import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allFormData: [],
};

export const formReducer = createSlice({
  name: "formflow",
  initialState,
  reducers: {
    setAllFormData: (state, action) => ({
      ...state,
      allFormData: action.payload,
    })
  },
});

export const { setAllFormData } = formReducer.actions;
export default formReducer.reducer;
