import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allPrompts: [],
  valueBasedPrompt: {}
};

export const documentReducer = createSlice({
  name: "document",
  initialState,
  reducers: {  
    setAllPromptsData: (state, action) => ({
      ...state,
      allPrompts: action.payload,
    }),
    setGeneratedValueBasedPrompts: (state, action) => ({
      ...state,
      valueBasedPrompt: action.payload,
    }),
  },
});

export const { setAllPromptsData, setGeneratedValueBasedPrompts } =
  documentReducer.actions;
export default documentReducer.reducer;
