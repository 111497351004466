import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ProfileIcon from "@mui/icons-material/Person";
import ClientModel from "./client";
import { useToken } from "../../context/TokenContext";
import { useLocation, useNavigate } from "react-router-dom";
import AddClientModal from "./AddClientModal";
import ViewModal from "./viewModal";
import FilterableColumnTable from "../../components/filterableColumnTable";
import dotnetApiClient from "../../config/dotnetApiClient";
import lawFirmLogos from "../../config/lawFirmLogos";
import { logErrorFunction } from "../../helper/service";

const Client: React.FC = () => {
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingClients, setLoadingClients] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { token, setToken } = useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [apiCalled, setApiCalled] = useState(false);

  const billingEntityId =
    location.state?.billingEntityId ||
    localStorage.getItem("billingEntityId") ||
    "";
  const uiFlowJsonID = location.state?.uiFlowJsonID || "";

  const lawyerData: any = JSON.parse(
    localStorage.getItem("lawyerData") || "{}"
  );

  useEffect(() => {
    if (billingEntityId) {
      localStorage.setItem("billingEntityId", billingEntityId);
    }
  }, [billingEntityId]);

  // useEffect(() => {
  //   if (billingEntityId) {
  //     const hardcodedLogoUrl = lawFirmLogos[billingEntityId];
  //     setLogoUrl(hardcodedLogoUrl || null);
  //   }
  // }, [billingEntityId]);

  useEffect(() => {
    if (logoUrl && window) {
      window.postMessage({ type: "UPDATE_LOGO", logoUrl }, "*");
    }
  }, [logoUrl]);

  const fetchDataFromAPI = async () => {
    if (!token) {
      logErrorFunction("Token is not available");
      return;
    }

    setLoading(true);
    try {
      const response = await dotnetApiClient().get("/clients");
      const data = response.data;
      setClients(data);
      setApiCalled(true);
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
        logErrorFunction("Error fetching clients:" + error);
      }
    } finally {
      setLoading(false);
      setLoadingClients(false);
    }
  };

  const fetchLogoForLawyer = async () => {
    if (!token) {
      logErrorFunction("Token is not available");
      return;
    }

    setLoading(true);
    try {
      const response: any = await dotnetApiClient().get("/Billing/logo", {
        responseType: "blob",
      });
      const data = response.data;
      const blob = new Blob([data], { type: data.type });
      const url = URL.createObjectURL(blob);

      setLogoUrl(url);
    } catch (error: any) {
      logErrorFunction("Error fetching logo:" + error);
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!apiCalled) {
      fetchDataFromAPI();
      fetchLogoForLawyer();
    }
  }, [apiCalled]);

  const handleAddClient = (newClient: ClientModel) => {
    setClients((prevClients) => [...prevClients, newClient]);
    fetchDataFromAPI();
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <Box sx={{ pt: "30px" }}>
        <Box className="lawyer_header_container">
          <Box className="add_client_btn_container">
            {logoUrl && (
              <img
                src={logoUrl}
                alt="Law Firm Logo"
                style={{
                  width: "60%",
                  height: "70px",
                  marginRight: "20px",
                  objectFit: "contain",
                }}
              />
            )}
            <Typography sx={{ fontSize: "26px", fontWeight: "700", minWidth: "60%" }} >
              Client List
            </Typography>
          </Box>
          <Box className="add_client_btn_container">
            <Button
              variant="outlined"
              sx={{ background: "#eff6ff" }}
              onClick={() => setShowAddClientModal(true)}
              startIcon={<AddIcon />}
              className="add_new_client_btn"
            >
              Add New Client
            </Button>
            <IconButton
              onClick={handleProfileMenuOpen}
              className="profile-btn"
              sx={{ mr: 2, color: "primary.main" }}
            >
              <ProfileIcon fontSize="large" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleProfileMenuClose}
              sx={{ mt: "45px" }}
            >
              {[
                <div key={1} style={{ padding: "10px 16px" }}>
                  <Typography variant="body1" sx={{ mb: 1, color: "#aaa" }}>
                    {lawyerData?.user?.name}
                  </Typography>
                  <Typography variant="body1" sx={{ color: "#aaa" }}>
                    {lawyerData?.user?.email}
                  </Typography>
                </div>,
                <MenuItem key={2} onClick={handleLogout}>
                  Logout
                </MenuItem>,
              ]}
            </Menu>
          </Box>
        </Box>
        {loadingClients ? (
          <div className="theme-loading">
            <CircularProgress size={28} />
          </div>
        ) : (
          <FilterableColumnTable
            clients={clients}
            setShowAddClientModal={setShowAddClientModal}
            handleAddClient={handleAddClient}
            fetchDataFromAPI={fetchDataFromAPI}
            setApiCalled={setApiCalled}
            showAddClientModal={showAddClientModal}
          />
        )}
      </Box>

      {showViewModal && (
        <ViewModal isViewPopup={showViewModal} setIsView={setShowViewModal} />
      )}
    </>
  );
};

export default Client;
