import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fade,
  Box,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import dotnetApiClient from "../../config/dotnetApiClient";
import ChatDialogComponent from './ChatDialogComponent';
import { useToken } from "../../context/TokenContext";
import { logErrorFunction, logFunction } from "../../helper/service";

interface ChatDialogProps {
  showChatModal: boolean;
  onClose: () => void;
  currentClient: any;
  setUnreadCount: (count: number) => void;
}

export const ChatDialog: React.FC<ChatDialogProps> = ({
  showChatModal,
  onClose,
  currentClient,
  setUnreadCount,
}) => {
  const { token }: any = useToken();
  const contentRef: any = useRef(null);
  const navigate = useNavigate();

  const [chatDetails, setChatDetails] = useState<any>(null);
  const [isChatLoaded, setIsChatLoaded] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      const chatList = document.querySelector('.str-chat__list') as HTMLElement | null;
      if (chatList) {
        const chatListHeight = chatList.offsetHeight;

        if (chatListHeight > 300) {
          chatList.style.height = '400px';
          chatList.scrollTop = chatList.scrollHeight;
        }
      }
    }, 3000);

  }, [currentClient]);

  useEffect(() => {
    const fetchChatDetails = async () => {
      if (!currentClient?.id) return;

      setIsChatLoaded(false); // Set loading state before fetching

      try {
        // First API call - Chat API
        const requestData = { withUserId: currentClient.id };
        const chatResponse = await dotnetApiClient().post("/chat", requestData);
        const chatData = chatResponse.data;

        // Set chat details after successful chat API call
        setChatDetails({
          apiKey: chatData?.apiKey,
          userId: chatData?.streamUsername,
          channelId: chatData?.streamChannelId,
          userToken: chatData?.userToken,
          userData: { id: chatData?.streamUsername },
        });

        // Second API call - Unread message count
        await getMessageUnReadCount();
      } catch (error: any) {
        logErrorFunction("Error fetching data: " + error);

        if (error?.response?.status === 401 || error?.response?.status === 406) {
          localStorage.removeItem('billingEntityId');
          navigate('/login');
        }
      } finally {
        setIsChatLoaded(true); // Set loading to true after both API calls are completed
      }
    };

    setUnreadCount(0);
    fetchChatDetails();
  }, [currentClient]);

  // Helper function to get unread message count
  const getMessageUnReadCount = async () => {
    if (!token || !currentClient?.id) {
      logErrorFunction("Token or current client ID is missing");
      return;
    }

    const requestedData = { withUserId: currentClient.id };
    try {
      const response = await dotnetApiClient().post("/chat/message/info", requestedData);
      const data = response.data;

    } catch (error: any) {
      logErrorFunction("Error fetching unread message count: " + error);

      if (error?.response?.status === 401 || error?.response?.status === 406) {
        localStorage.removeItem('billingEntityId');
        navigate('/login');
      }
    }
  };

  const handleCloseDialog = () => {
    onClose();
  };

  return (
    <Dialog
      className="chat_modal"
      open={showChatModal}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          borderRadius: 3,
          width: '1000px',
          maxWidth: '100%',
          maxHeight: '650px',
          height: '650px'
        },
      }}
      TransitionComponent={Fade}
      transitionDuration={300}
    >
      <div className="chat_modal_container" ref={contentRef}>
        <DialogTitle
          className="chat_modal_title"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '20px',
            fontWeight: 'bold',
            background: '#ccc',
            borderRadius: 2
          }}
        >
          Chat
        </DialogTitle>
        <DialogContent className="chat_modal_content" sx={{ minHeight: "532px", height: '532px' }}>
          {isChatLoaded ? (
            chatDetails && (
              <ChatDialogComponent
                userId={chatDetails?.userId}
                apiKey={chatDetails?.apiKey}
                channelId={chatDetails?.channelId}
                userToken={chatDetails?.userToken}
                userData={chatDetails?.userData}
              />
            )
          ) : (
            <Box className="chat_modal_loader" sx={{
              display: 'flex',
              alignItems: 'center',
              height: "150px",
              justifyContent: "center"
            }}>
              <CircularProgress color="primary" />
            </Box>
          )}
        </DialogContent>
        <DialogActions className="chat_modal_actions" sx={{ paddingX: 3 }}>
          <Button
            className="chat_modal_close_btn"
            onClick={handleCloseDialog}
            variant="outlined"
            color="inherit"
            sx={{
              color: 'text.secondary',
              borderColor: 'grey.500',
              '&:hover': {
                borderColor: 'grey.700',
                backgroundColor: 'grey.100',
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ChatDialog;
