import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import SideLogo from "../../assets/images/logo-white.png";
import { useToken } from "../../context/TokenContext";
import dotnetApiClient from "../../config/dotnetApiClient";
import { formatDateTime, logErrorFunction } from "../../helper/service";
import { generateByPromptsId, getAllPromptsData } from "../../store/thunk/documentThunk";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { notificationSuccess } from "../../store/slices/notificationSlice";

interface ClientType {
  id: number;
  name: string;
  email: string;
  phone: string;
  progressPercentage: number;
  caseType: "Marriage AOS";
  createDtUtc: string;
  lastLoginDtUtc: string;
  formsForCaseTypes: FormsForCaseTypes[];
}

interface FormsForCaseTypes {
  caseID: number;
  caseName: string;
  formNames: string[];
}

interface IUserDocument {
  udId: string;
  userId: string;
  originalFileName: string;
  title: string;
  description: string;
  selectedClientDetails: any;
}

const ViewComponent = (props: any) => {
  const { isViewPopup, setIsView, userId, selectedClientDetails } = props;
  const dispatch: any = useAppDispatch();
  const { allPrompts } = useAppSelector((state: any) => state.documentReducer);

  const [loading, setLoading] = useState(false);
  const [clientData, setClientData] = useState<ClientType>();
  const [uploadedDocuments, setUploadedDocuments] = useState<IUserDocument[]>(
    []
  );
  const [packetHistory, setPacketHistory] = useState<any>([]);
  const { token } = useToken();
  const navigate = useNavigate();
  const [selectedCaseType, setSelectedCaseType] = useState(0);
  const [selectedCaseTypeFormList, setSelectedCaseTypeFormList] = useState([]);
  const [formValuesBasedList, setFormValuesBasedList] = useState<any>([]);
  const [docBasedList, setDocBasedList] = useState(["Generate Employment History 1", "Generate Employment History 2", "Generate Employment History 3", "Generate Employment History 4", "Generate Employment History 5"]);
  const [selectedFormValuesBasedValue, setSelectedFormValuesBasedValue] = useState<number | string>('');
  const [selectedDocBasedValue, setSelecetedDocBasedValue] = useState("");

  useEffect(() => {
    let tempPromptList: any = [];

    if (allPrompts?.length > 0) {
      allPrompts?.map((data: any, index: number) => {
        tempPromptList.push({ "id": data?.id, "text": data?.text });
      })

      setFormValuesBasedList(tempPromptList);
      setSelectedFormValuesBasedValue(tempPromptList?.[0]?.id);
    }
  }, [allPrompts]);

  useEffect(() => {
    dispatch(getAllPromptsData());
  }, []);

  const getUserPDFDownload = async (_request: any) => {
    const { userId, formName, caseId } = _request;
    setLoading(true);

    try {
      const response = await dotnetApiClient().get(
        `/pdfpreview/user/${userId}/form/${formName}?caseId=${caseId}`,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Form_${formName}_${new Date()
          .toISOString()
          .replace(/[:.]/g, "_")}.pdf`;
        link.click();
      }
    } catch (error: any) {
      logErrorFunction("Error downloading PDF:" + error);

      if (
        error?.response?.status === 401 ||
        error?.response?.status === 406 ||
        error?.response?.status === 406
      ) {
        localStorage.removeItem("billingEntityId");
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadPacketFromHistory = async (_request: any) => {
    const { versionId } = _request;
    setLoading(true);

    try {
      const response = await dotnetApiClient().get(
        `/evidencePacket/versions/${versionId}/download`,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Packet_${versionId}_${new Date()
          .toISOString()
          .replace(/[:.]/g, "_")}.pdf`;
        link.click();
      }
    } catch (error: any) {
      logErrorFunction("Error downloading PDF:" + error);
    } finally {
      setLoading(false);
    }
  };

  const handlePdfClick = (pdfName: any) => {
    const userFormData = {
      userId,
      formName: pdfName,
      caseId: selectedCaseType,
    };
    getUserPDFDownload(userFormData);
  };

  const getEvidencePacketDownload = async (_request: any) => {
    const { userId, caseId } = _request;
    setLoading(true);

    try {
      const response = await dotnetApiClient().get(
        `/evidencepacket/download/user/${userId}?caseId=${caseId}`,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Evidence_Form_${new Date()
          .toISOString()
          .replace(/[:.]/g, "_")}.pdf`;
        link.click();
      }
    } catch (error: any) {
      logErrorFunction("Error downloading evidence packet:" + error);

      if (error?.response?.status === 401 || error?.response?.status === 406) {
        localStorage.removeItem("billingEntityId");
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadPacket = () => {
    const userFormData = {
      userId,
      caseId: selectedCaseType,
    };
    getEvidencePacketDownload(userFormData);
  };

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const response = await dotnetApiClient().get(
        `/UserDocument/user/${userId}/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUploadedDocuments(response.data);
    } catch (error: any) {
      logErrorFunction("Error fetching documents:" + error);
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        localStorage.removeItem("billingEntityId");
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadDocument = (doc: IUserDocument) => {
    const linkUrl = `${dotnetApiClient().defaults.baseURL}/UserDocument/user/${doc.userId
      }/preview/${doc.udId}`;

    fetch(linkUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", doc.originalFileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .catch((error) => {
        logErrorFunction(
          "There was a problem with the download request:" + error
        );
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await dotnetApiClient().get(`/clients/${userId}`);
        const data = response.data;

        if (data) {
          const tempSelectedData = {
            id: data.id,
            name: data.name,
            email: data.email,
            phone: data.phoneNumber,
            progressPercentage: data.progressPercentage,
            caseType: data.caseType,
            createDtUtc: data.createDtUtc,
            lastLoginDtUtc: data.lastLoginDtUtc,
            formsForCaseTypes: data?.formsForCaseTypes,
          };

          setSelectedCaseType(
            selectedClientDetails?.formsForCaseTypes[0]?.caseId
          );
          setSelectedCaseTypeFormList(data?.formsForCaseTypes[0]?.formNames);
          setClientData(tempSelectedData);
        }
      } catch (error: any) {
        logErrorFunction("Error fetching data:" + error);

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 406
        ) {
          localStorage.removeItem("billingEntityId");
          navigate("/login");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchDocuments();
  }, [userId, token]);

  const formLabels: { [key: string]: string } = {
    "i-864a-household-member-1": "I864a Household Member of Petitioner",
    "i-864a-household-member-2": "I864a Household Member of Joint Sponsor",
    "i-864-joint-sponsor": "I864 for Joint Sponsor",
    "g-28-immigrant": "G28 for Immigrant",
    "g-28-petitioner": "G28 for Petitioner",
  };

  const handleCaseType = (e: any) => {
    setSelectedCaseType(e?.target?.value);
  };
  const handleFormValueBased = (e: any) => {
    setSelectedFormValuesBasedValue(e?.target?.value);
  };
  const handleDocBased = (e: any) => {
    setSelecetedDocBasedValue(e?.target?.value);
  };

  useEffect(() => {
    const getPacketHistory = async () => {
      try {
        const axiosInstance = dotnetApiClient();
        const response = await axiosInstance.get(
          `/evidencePacket/user/${userId}/case/${selectedCaseType}/versions`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data) {
          setPacketHistory(response?.data);
        } else {
          logErrorFunction("Failed to delete client");
        }
      } catch (error: any) {
        logErrorFunction("Error deleting client:" + error);
      }
    };
    getPacketHistory();
  }, [selectedCaseType, userId]);

  useEffect(() => {
    const updatedPDFList: any = clientData?.formsForCaseTypes?.filter(
      (x: any) => x?.caseId === selectedCaseType
    );
    setSelectedCaseTypeFormList(updatedPDFList?.[0]?.formNames);
  }, [selectedCaseType]);

  const renderMenuItem = () => {
    return clientData?.formsForCaseTypes?.map((list: any, index: number) => (
      <MenuItem key={index} value={list.caseId} className="view_modal_case_type_item">
        {`${list.caseName}`}
      </MenuItem>
    ));
  };
  const renderFormValueBasedMenuItem = () => {
    return formValuesBasedList?.map((list: any, index: number) => (
      <MenuItem key={index} value={list?.id} id="view_modal_form_value_based_item" sx={{ maxWidth: '100px' }}>
        {list?.text}
      </MenuItem>
    ));
  };
  const renderDocBasedMenuItem = () => {
    return docBasedList?.map((list: any, index: number) => (
      <MenuItem key={index} value={list} id="view_modal_doc_based_item">
        {list}
      </MenuItem>
    ));
  };
  
  const handleFormValueBasedRun = () => {
    dispatch(generateByPromptsId({promptId: selectedFormValuesBasedValue, userId}));
  }

  const handleDocBasedRun = () => {
    dispatch(notificationSuccess("Client document based intelligence created successfully"));

    console.log("Doc based Value", selectedDocBasedValue);
  }

  return (
    <Dialog
      open={isViewPopup}
      onClose={() => setIsView(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog-view-client"
    >
      {loading && (
        <div className="loader_dialog">
          <img src={SideLogo} className="mb-3" alt="sidelogo" />
          <CircularProgress sx={{ color: "#fff" }} />
          <span className="mt-3 text-white">Getting things ready...</span>
        </div>
      )}
      <>
        <Box
          className="view_modal_header"
          display="flex"
          justifyContent={"space-between"}
          sx={{ borderBottom: 1, borderColor: "#eee" }}
        >
          <DialogTitle className="view_modal_title">Client Details</DialogTitle>
          <Button className="view_modal_close_btn" variant="text" onClick={() => setIsView(false)}>
            <CloseIcon style={{ color: "red" }} />
          </Button>
        </Box>

        <DialogContent sx={{ mt: "20px" }} className="view_modal_content">
          <div className="userViewDetail">
            <div className="basicDetails" style={{ marginBottom: "20px" }}>
              <div className="view_modal_label" style={{ marginBottom: "10px" }}>
                <strong>Name:</strong> {clientData?.name || ""}
              </div>
              <div className="view_modal_label" style={{ marginBottom: "10px" }}>
                <strong>Email:</strong> {clientData?.email || ""}
              </div>
              <div className="view_modal_label" style={{ marginBottom: "10px" }}>
                <strong>Case Type:</strong>{" "}
                {clientData?.formsForCaseTypes[0]?.caseName || ""}
              </div>
              <div className="view_modal_label" style={{ marginBottom: "10px" }}>
                <strong>Progress %:</strong>{" "}
                {clientData?.progressPercentage !== undefined
                  ? `${clientData?.progressPercentage}`
                  : "N/A"}
              </div>
              <div className="view_modal_label" style={{ marginBottom: "10px" }}>
                <strong>Phone:</strong> {clientData?.phone || ""}
              </div>
              <div className="view_modal_label" style={{ marginBottom: "10px" }}>
                <strong>Last login at:</strong>{" "}
                {clientData?.lastLoginDtUtc
                  ? formatDateTime(clientData?.lastLoginDtUtc)
                  : "N/A"}
              </div>
            </div>

            <div style={{ marginBottom: "20px" }} className="view_modal_case_type_container">
              <strong>Forms: </strong>

              <div
                className="view_modal_case_type_selection"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 0,
                }}
              >
                <label className="view_modal_case_type_select_label" style={{ fontWeight: "700" }}>Select Case Type: </label>
                <FormControl sx={{ m: 1, minWidth: 100 }} className="view_modal_case_type_form_control">
                  <InputLabel id="demo-simple-select-label">
                    Case Type
                  </InputLabel>
                  <Select
                    labelId="caseTypeSelection"
                    id="caseTypeSelection"
                    value={selectedCaseType}
                    label="Case Type"
                    name="CaseType"
                    onChange={(e: any) => handleCaseType(e)}
                  >
                    {renderMenuItem()}
                  </Select>
                </FormControl>
              </div>
              <div className="view_modal_case_type_form_container">
                {selectedCaseTypeFormList?.map((urls: string) => (
                  <Button
                    className="view_modal_case_type_form_url"
                    key={urls}
                    onClick={() => handlePdfClick(urls)}
                    sx={{
                      px: "10px",
                      py: "4px",
                      background: "#2557b3",
                      borderRadius: "8px",
                      mx: "6px",
                      my: "2px",
                      minWidth: "80px",
                      fontSize: "12px",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "rgba(37, 87, 179, 0.9)",
                      },
                    }}
                  >
                    {formLabels[urls] || urls}
                  </Button>
                ))}
                {/* {pdfUrlList?.map((urls: string) => (
                  <Button
                    key={urls}
                    onClick={() => handlePdfClick(urls)}
                    sx={{
                      px: "10px",
                      py: "4px",
                      background: "#2557b3",
                      borderRadius: "8px",
                      mx: "6px",
                      my: "2px",
                      minWidth: "80px",
                      fontSize: "12px",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "rgba(37, 87, 179, 0.9)",
                      },
                    }}
                  >
                    {formLabels[urls] || urls}
                  </Button>
                ))} */}
              </div>
            </div>

            <div className="view_modal_evidence_packet_container" style={{ marginBottom: "10px" }}>
              <strong>Evidence packet: </strong>
              <Button
                className="view_modal_evidence_download_packet"
                onClick={() => handleDownloadPacket()}
                sx={{
                  px: "10px",
                  py: "4px",
                  background: "#2557b3",
                  borderRadius: "8px",
                  mx: "6px",
                  my: "2px",
                  minWidth: "80px",
                  fontSize: "12px",
                  color: "#fff",
                  ":hover": {
                    backgroundColor: "rgba(37, 87, 179, 0.9)",
                  },
                }}
              >
                Download Packet
              </Button>
            </div>
            <div className="view_modal_packet_history_container" style={{ marginBottom: "10px" }}>
              <strong>Packet history: </strong>
              {packetHistory?.length ? (
                packetHistory?.map((p: any, index: any) => (
                  <div key={index} className="mb-2 view_modal_packet_history_download_btn_container">
                    <Button
                      className="mb-2 view_modal_packet_history_download_btn"
                      onClick={() =>
                        handleDownloadPacketFromHistory({ versionId: p?.id })
                      }
                      sx={{
                        mr: "10px",
                        minWidth: "80px",
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "#2557b3",
                      }}
                    >
                      Download
                    </Button>
                    {formatDateTime(p?.uploadedOnDtUtc)}
                  </div>
                ))
              ) : (
                <p className="view_modal_no_packet_history_txt">No packet version history found</p>
              )}
            </div>

            <div className="view_modal_upload_doc_container" style={{ marginBottom: "10px", marginTop: "30px" }}>
              <strong>Uploaded Documents:</strong>
              <TableContainer
                className="view_modal_upload_doc_table_container"
                component={Paper}
                sx={{ mt: 2, maxHeight: "400px", overflow: "auto" }}
              >
                <Table size="small" className="view_modal_upload_doc_table">
                  <TableHead className="view_modal_upload_doc_table_head">
                    <TableRow className="view_modal_upload_doc_table_row">
                      <TableCell className="view_modal_upload_doc_table_title" sx={{ fontSize: "14px", fontWeight: "bold" }}>
                        Title
                      </TableCell>
                      <TableCell className="view_modal_upload_doc_table_desc" sx={{ fontSize: "14px", fontWeight: "bold" }}>
                        Description
                      </TableCell>
                      <TableCell className="view_modal_upload_doc_table_doc" sx={{ fontSize: "14px", fontWeight: "bold" }}>
                        Document
                      </TableCell>
                      <TableCell className="view_modal_upload_doc_table_action" sx={{ fontSize: "14px", fontWeight: "bold" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="view_modal_upload_doc_table_body">
                    {uploadedDocuments.length > 0 ? (
                      uploadedDocuments.map((doc) => (
                        <TableRow className="view_modal_upload_doc_table_row" key={doc.udId}>
                          <TableCell className="view_modal_upload_doc_table_cell" sx={{ fontSize: "12px" }}>
                            {doc.title}
                          </TableCell>
                          <TableCell className="view_modal_upload_doc_table_cell" sx={{ fontSize: "12px" }}>
                            {doc.description}
                          </TableCell>
                          <TableCell className="view_modal_upload_doc_table_cell" sx={{ fontSize: "12px" }}>
                            {doc.originalFileName}
                          </TableCell>
                          <TableCell className="view_modal_upload_doc_table_cell">
                            <Button
                              className="view_modal_download_doc_button"
                              onClick={() => handleDownloadDocument(doc)}
                              sx={{
                                px: "8px",
                                py: "2px",
                                background: "#2557b3",
                                color: "#fff",
                                fontSize: "12px",
                                ":hover": {
                                  backgroundColor: "rgba(37, 87, 179, 0.9)",
                                },
                              }}
                            >
                              Download
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow className="view_modal_upload_doc_table_row">
                        <TableCell className="view_modal_no_doc_upload_txt" colSpan={4} sx={{ textAlign: "center" }}>
                          No documents uploaded
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className="view_modal_upload_doc_container" style={{ marginBottom: "10px", marginTop: "30px" }}>
              <strong>Intelligence</strong>

              {/* This is for value based generate prompts */}
              <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '30px' }}>
                  <label className="view_modal_case_type_select_label" style={{ minWidth: 200 }}>Client Form Values Based: </label>
                  <FormControl sx={{ m: 1, minWidth: 300, maxWidth: '300px' }} className="view_modal_case_type_form_control">
                    <InputLabel id="demo-simple-select-label" sx={{ background: 'white', px: '5px' }}>
                      Form Values Based
                    </InputLabel>
                    <Select
                      labelId="caseTypeSelection"
                      id="caseTypeSelection"
                      value={selectedFormValuesBasedValue}
                      label="Case Type"
                      name="CaseType"
                      onChange={handleFormValueBased}
                    >
                      {renderFormValueBasedMenuItem()}
                    </Select>
                  </FormControl>
                  <Button
                    className="view_modal_evidence_download_packet"
                    onClick={() => handleFormValueBasedRun()}
                    sx={{
                      p: "10px",
                      background: "#2557b3",
                      borderRadius: "8px",
                      mx: "6px",
                      minWidth: "80px",
                      fontSize: "12px",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "rgba(37, 87, 179, 0.9)",
                      },
                    }}
                  >
                    Run
                  </Button>
                </div>

                {/* This is for Document based prompt */}
                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <label className="view_modal_case_type_select_label" style={{ minWidth: 200 }}>Client Documents Based: </label>
                  <FormControl sx={{ m: 1, minWidth: 300 }} className="view_modal_case_type_form_control">
                    <InputLabel id="demo-simple-select-label" sx={{ background: 'white', px: '5px' }}>
                      Documents Based
                    </InputLabel>
                    <Select
                      labelId="client_documents_based"
                      id="clientDocumentsBased"
                      value={selectedDocBasedValue || docBasedList[0]}
                      label="Client Documents Based"
                      name="ClientDocumentsBased"
                      onChange={(e: any) => handleDocBased(e)}
                    >
                      {renderDocBasedMenuItem()}
                    </Select>
                  </FormControl>
                  <Button
                    className="view_modal_evidence_download_packet"
                    onClick={() => handleDocBasedRun()}
                    sx={{
                      p: "10px",
                      background: "#2557b3",
                      borderRadius: "8px",
                      mx: "6px",
                      minWidth: "80px",
                      fontSize: "12px",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "rgba(37, 87, 179, 0.9)",
                      },
                    }}
                  >
                    Run
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default ViewComponent;
