import React from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  CircularProgress,
  Checkbox,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Client from "./client";
import dotnetApiClient from "../../config/dotnetApiClient";
import axios from "axios";
import { logErrorFunction } from "../../helper/service";

interface AddClientModalProps {
  open: boolean;
  onClose: () => void;
  onAddClient: (client: Client) => void;
  fetchDataFromAPI: () => void;
  setApiCalled: (flag: boolean) => void;
  client: any;
}

const AddClientModal: React.FC<AddClientModalProps> = ({
  open,
  onClose,
  onAddClient,
  fetchDataFromAPI,
  setApiCalled,
  client,
}) => {
  const navigate = useNavigate();

  const validateData: any =
    typeof localStorage !== "undefined"
      ? JSON.parse(localStorage.getItem("supportedCaseTypes") || "null")
      : "";

  // Create a function to get the latest version of each form
  const getLatestForms = (dataArray: any[]) => {
    const formMap: Record<string, any> = {};

    dataArray.forEach((data) => {
      const { title, version } = data;

      // If the title is not in the map or current version is greater, update it
      if (!formMap[title] || version > formMap[title].version) {
        formMap[title] = data;
      }
    });

    // Convert formMap back to an array
    return Object.values(formMap);
  };
  // Filter validateData to get the latest version for each form
  const latestForms = getLatestForms(validateData);

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(60, "Name should not exceed 40 characters")
      .required("Name is required"),
    phoneNumber: Yup.string()
      .matches(
        /^[0-9]{8,15}$/,
        "Phone number should be between 8 and 15 digits"
      )
      .required("Phone number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    ids: Yup.array().min(1).required("Form name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: client?.name || "",
      phoneNumber: client?.phoneNumber || "",
      email: client?.email || "",
      ids: client?.formsForCaseTypes?.length
        ? client?.formsForCaseTypes?.map((c: any) => c?.caseId)
        : [],
      uiFlowJsonID: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      const updatedClient = {
        ...client,
        name: values.name,
        email: values.email,
        phoneNumber: values.phoneNumber,
        // progressPercentage: 0,
        caseType: "",
        jsonId: "",
        formName: values.ids,
        formVersion: "",
        createDtUtc: "",
        uiFlowJsonID: values.uiFlowJsonID,
      };

      const requestData = {
        ...(client
          ? {
            id: updatedClient.id,
            progressPercentage: updatedClient.progressPercentage,
          }
          : {}),
        Name: updatedClient.name,
        PhoneNumber: updatedClient.phoneNumber,
        Email: updatedClient.email,
        caseIds: values.ids,
        userType: updatedClient.userType || "user",
      };

      try {
        const response = client
          ? await dotnetApiClient().put(`/clients/${client.id}`, requestData)
          : await dotnetApiClient().post("/clients", requestData);
        const data = response.data;
        if (response.status === 201 || response.status === 200) {
          !client && onAddClient(data);
          setApiCalled(false);
          fetchDataFromAPI();
          onClose();
        } else {
          logErrorFunction("Error adding client:" + data);
        }
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 409) {
            setFieldError(
              "email",
              "Looks like this email is already registered in our records"
            );
          } else if (
            error?.response?.status === 401 ||
            error?.response?.status === 406
          ) {
            localStorage.removeItem("billingEntityId");
            navigate("/login");
          } else {
            logErrorFunction("Error adding client:" + error.response?.data);
          }
        } else {
          logErrorFunction("Unexpected error:" + error);
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 406
          ) {
            localStorage.removeItem("billingEntityId");
            navigate("/login");
          }
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Modal className="add_edit_modal" open={open} onClose={onClose}>
      <Box className="add_edit_modal_inner_container" sx={{ ...modalStyle }}>
        <Typography className="add_edit_modal_title" variant="h6" mb={2}>
          {client ? "Edit Client" : "Add New Client"}
        </Typography>
        <form onSubmit={formik.handleSubmit} id="client_form">
          <TextField
            id="add_edit_name"
            label="Client Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            fullWidth
            margin="normal"
          />
          <TextField
            id="add_edit_number"
            label="Client Phone Number"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            fullWidth
            margin="normal"
          />
          <TextField
            id="add_edit_email"
            label="Client Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            fullWidth
            margin="normal"
          />
          <FormControl
            className="add_edit_form_control"
            fullWidth
            margin="normal"
            error={formik.touched.ids && Boolean(formik.errors.ids?.length)}
            variant="outlined"
          >
            <InputLabel id="form-name-label">Case Type</InputLabel>
            <Select
              className="add_edit_select"
              labelId="form-name-label"
              name="ids"
              value={formik.values.ids}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Case Type"
              multiple
              renderValue={(selected) => (
                <Box className="add_edit_case_type_container" sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value: any) => {
                    // Find the matching form object from latestForms using the selected ID
                    const form = latestForms.find(
                      (item) => item.caseId === value
                    );
                    // Display the title and version if found, or just the ID as fallback
                    return (
                      <Chip
                        className="add_edit_chip"
                        key={value}
                        label={form ? `${form.title} - ${form.version}` : value}
                      />
                    );
                  })}
                </Box>
              )}
            >
              <MenuItem className="add_edit_menuitem_disable" value="" disabled></MenuItem>
              {latestForms &&
                latestForms.length > 0 &&
                latestForms.map((data: any, index: number) => (
                  <MenuItem className="add_edit_menuitem" key={index} value={data?.caseId}>
                    <Checkbox
                      className="add_edit_checkbox"
                      checked={(formik.values.ids as any).includes(
                        data?.caseId
                      )}
                    />
                    {data?.title + " - " + data?.version}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <Box className="add_edit_button_container" mt={2} display="flex" justifyContent="flex-end">
            <Button
              id="add_edit_cancel_btn"
              onClick={onClose}
              sx={{ mr: 1 }}
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              id="add_edit_btn"
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? (
                <CircularProgress size={24} />
              ) : client ? (
                "Edit Client"
              ) : (
                "Add Client"
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default AddClientModal;
